.bg-light {
    background: #fff !important;
    height: 67px;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 3px 6px #00000069;
}

.searchbar {
    background: url(../images/backgrounds/top_home.jpg) no-repeat center;
    background-size: cover;
    height: 155px;
}

.searchbar .d-flex {
    height: 100%;
}

.drop_full .dropdown-toggle {
    width: 100%;
    text-align: left;
}

img.thumbnail-image {
    width: 35px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.dropdown-item {
    padding: 0.25rem 12px;
}

@media (max-width: 575px) {
    .mt-xs-2 {
        margin: 5px 0px;
    }
    .searchbar {
        height: 275px;
    }
}

section {
    width: 100%;
    display: block;
    margin: 0px;
}

.bnr .slick-slider {
    overflow: hidden;
}

.bnr .slide {
    padding: 15px;
}

.bnr .slick-slide:not(.slick-active) {
    opacity: 0.5;
    transition: 1s;
    transform: translateY(50px);
}

.bnr .slick-slide {
    transform: translateY(0px);
    transition: 1s;
    margin-bottom: 50px;
}

.full-width {
    width: 100%;
}

.img_hold {
    border-radius: 30px;
    overflow: hidden;
}

@media (max-width: 992px) {
    .bnr .slick-slide:not(.slick-active) {
        transform: none;
    }
    .bnr .slide {
        padding: 15px;
    }
    .img_hold img {
        border-radius: 5px;
        overflow: hidden;
        min-height: 400px;
        object-fit: cover;
    }
}

.wishlist_hold {
    padding: 10px 15px;
}

.wishlist {
    background: #f9b303;
    width: 33px;
    display: inline-block;
    height: 31px;
    vertical-align: middle;
    border-radius: 5px;
    /* margin-left: 15px; */
    margin-right: 10px;
}

.wishlist img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 7px;
    vertical-align: middle;
    display: block;
    filter: brightness(100);
}

.home_head_small {
    font-size: 16px;
    font-weight: bold;
    color: #575757;
    display: inline-block;
}

.relative.hover_card img.full-width {
    min-height: 255px;
    object-fit: cover;
}

.wish_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    overflow: hidden;
}

.wish-cap {
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
    background: #fff;
    margin-top: -10px;
    z-index: 99999999;
    position: relative;
}

.wish-cap h2 {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish_card .tags p {
    display: inline-block;
    /* margin: 5px 10px 5px 0px; */
    margin: 0px 10px 0px 0px;
    float: left;
    font-size: 12px;
}

.wish_card .tags p::after {
    display: inline-block;
    content: "|";
    margin-left: 10px;
}

.wish_card .tags p:last-child::after {
    display: none;
}

.wish-cap h3.price {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish-cap a.addCart {
    color: #2696cc;
    border: 1px solid #2696cc;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.ve-scroll {
    padding: 15px 0px;
}

.wishlist_hold .slick-slide .wish-cap a.addCart img {
    display: inline-block;
}

.wishlist_hold .slick-initialized .slick-slide {
    display: block;
    padding: 15px 0px;
    padding-right: 15px;
}

.wish_card .pull-right img,
.wish_card .pull-right small {
    display: inline-block;
}

.store_card {
    box-shadow: 0px 3px 6px #00000029;
}

.store_card .store-cap {
    text-align: center;
    border: 1px solid #2696cc;
    padding: 10px 15px;
}

.wish_card,
.wish_card:focus,
.wish_card:visited {
    outline: none !important;
}

.box {
    /* border: 0.3px solid #707070; */
    box-shadow: 0px 0px 3px #00000029;
    padding: 15px;
}

.mapLinks {
    margin-top: 20px;
}

.map_foot {}

.pl-5 {
    padding-left: 50px;
}

.map_links {
    display: flex;
    justify-content: unset;
    flex-flow: wrap;
    margin-top: 30px;
}

.map_links a {
    color: #575757;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    margin: 10px;
    min-width: 115px;
}

.wishlist_hold {
    padding: 10px 15px;
}

.wishlist {
    background: #f9b303;
    width: 33px;
    display: inline-block;
    height: 31px;
    vertical-align: middle;
    border-radius: 5px;
    /* margin-left: 15px; */
    margin-right: 10px;
}

.wishlist img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 7px;
    vertical-align: middle;
    display: block;
    filter: brightness(100);
}

.wish_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    overflow: hidden;
}

.wish-cap {
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
    background: #fff;
    margin-top: -10px;
    z-index: 99999999;
    position: relative;
}

.wish-cap h2 {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish_card .tags p {
    display: inline-block;
    /* margin: 5px 10px 5px 0px; */
    margin: 0px 10px 0px 0px;
    float: left;
    font-size: 12px;
}

.wish_card .tags p::after {
    display: inline-block;
    content: "|";
    margin-left: 10px;
}

.wish_card .tags p:last-child::after {
    display: none;
}

.wish-cap h3.price {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish-cap a.addCart {
    color: #2696cc;
    border: 1px solid #2696cc;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.ve-scroll {
    padding: 15px 0px;
}

.wishlist_hold .slick-slide .wish-cap a.addCart img {
    display: inline-block;
}

.wishlist_hold .slick-initialized .slick-slide {
    display: block;
    padding: 15px 0px;
    padding-right: 15px;
}

.wish_card .pull-right img,
.wish_card .pull-right small {
    display: inline-block;
}

.store_card {
    box-shadow: 0px 3px 6px #00000029;
}

.store_card .store-cap {
    text-align: center;
    border: 1px solid #2696cc;
    padding: 10px 15px;
}

.wish_card,
.wish_card:focus,
.wish_card:visited {
    outline: none !important;
}

.list-group-item,
.list-group-item.active {
    padding: 0px 0px;
    border: 1px solid transparent !important;
    margin: 0 0 6px 0px !important;
    border-radius: 10px !important;
    box-shadow: 0px 3px 6px #00000029;
    height: 57px;
    cursor: pointer;
    transition: 0.5s;
    color: #4b4b4b;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list-group-item a,
.list-group-item.active a {
    color: #4b4b4b;
    text-decoration: none;
}

.list-group-item:hover,
.list-group-item.active {
    transition: 0.5s;
    box-shadow: 0px 3px 6px #00000069;
    background: #2696cc;
    color: #fff;
}

.list-group-item:hover a,
.list-group-item.active a {
    transition: 0.5s;
    color: #fff;
}

img.linkimg {
    background: #fff;
    padding: 10px 20px;
    margin-right: 15px;
    border-radius: 10px;
    object-fit: contain;
    height: 55px;
    width: 65px;
    /* border-right: 1px solid #707070 !important; */
    box-shadow: 0px 3px 6px #00000029;
}

.hover_card {
    overflow: hidden;
    border-radius: 15px !important;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 15px;
}

.hover_card .caption {
    border: 0.3px solid transparent;
    padding: 15px;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transform: translate(0px, 190px);
    transition: 0.5s;
    border-radius: 15px;
}

.hover_card .caption a {
    clear: both;
    width: auto;
    float: right;
    background: #2696cc;
    display: inline;
    padding: 0px 10px;
    border-radius: 15px;
    border: 1px solid #707070;
    margin: 5px 5px !important;
    color: #fff;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.hover_card .caption p {
    width: auto;
    display: inline;
    padding: 0px 10px;
    border-radius: 15px;
    border: 1px solid #707070;
    margin: 5px 5px !important;
    float: left;
    font-size: 12px;
}

.hover_card:hover .caption {
    transform: translate(0px, 0px);
    transition: 0.5s;
    /* border: 1px solid #707070; */
}

.hover_card .caption h2 {
    transform: translate(-60px, -15px);
    transition: 0.5s;
    display: inline-block;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
}

.hover_card:hover .caption h2 {
    transform: translate(10px, 0px);
    transition: 0.5s;
}

.hover_card .caption .icon {
    position: relative;
    right: 0;
    transition: 0.5s;
    transform: translate(235px, -40px);
    padding: 15px;
    background: #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    box-shadow: 0px 3px 6px #00000029;
}

.hover_card:hover .caption .icon {
    transform: translate(0px, 0px);
    transition: 0.5s;
    margin-bottom: 15px;
}

@media (max-width: 1399px) {
    .hover_card .caption {
        transform: translate(0px, 150px);
    }
    .hover_card .caption .icon {
        transform: translate(181px, -40px);
    }
}

@media (max-width: 1199px) {
    .hover_card .caption {
        transform: translate(0px, 175px);
    }
    .hover_card .caption .icon {
        transform: translate(215px, -40px);
    }
}

@media (max-width: 992px) {
    .hover_card .caption {
        transform: translate(0px, 130px);
    }
    .hover_card .caption .icon {
        transform: translate(151px, -40px);
        height: 50px;
        width: 50px;
    }
    .hover_card .caption h2 {
        transform: translate(-50px, -15px);
    }
    .hover_card:hover .caption {
        padding: 10px;
    }
    .hover_card:hover .caption .icon {
        margin: 0px 0px 5px 0px;
    }
    .hover_card .caption p {
        margin: 2px 2px !important;
        padding: 0px 5px;
    }
}

@media (max-width: 767px) {
    .hover_card .caption {
        transform: translate(0px, 150px);
    }
    .hover_card .caption .icon {
        transform: translate(180px, -40px);
        height: 50px;
        width: 50px;
    }
    .hover_card .caption h2 {
        transform: translate(-50px, -15px);
    }
    .hover_card:hover .caption {
        padding: 10px;
    }
    .hover_card:hover .caption .icon {
        margin: 0px 0px 5px 0px;
    }
    .hover_card .caption p {
        margin: 2px 2px !important;
        padding: 0px 5px;
    }
}

@media (max-width: 575px) {
    .hover_card .caption {
        position: relative;
        transform: none;
        top: -15px;
    }
    .hover_card .caption .icon {
        position: absolute;
        right: 15px;
        transform: none;
        top: -25px;
    }
    .hover_card .caption h2 {
        transform: none;
        margin-bottom: 5px;
        margin-left: 5px;
    }
    .hover_card .caption a {
        float: right;
        position: relative;
        clear: left;
        display: block;
        left: auto;
        top: 0;
        right: auto;
        width: auto;
        text-align: center;
        margin: 10px auto !important;
    }
    .caption .sub-tags {
        display: block;
        clear: both;
        width: 100%;
        float: left;
    }
}


/*Theme variables*/

.btn-success {
    border-color: #67a400;
    background: #67a400;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    transition: 0.5s;
    transform: translateY(0px);
}

.btn-success:hover {
    transition: 0.5s;
    transform: translateY(-2px);
    box-shadow: 0px 3px 6px #00000069;
}

.btn-success img {
    max-width: 15px;
    margin-right: 5px;
}

.btn-primary {
    border-color: #2696cc;
    background: #2696cc;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
    border-radius: 10px;
    transform: translateY(0px);
}

.btn-primary:hover {
    transition: 0.5s;
    transform: translateY(-2px);
    box-shadow: 0px 3px 6px #00000069;
    border-color: #2696cc;
    background: #2696cc;
    color: #fff;
}

.btn-primary img {
    max-width: 15px;
    margin-right: 5px;
    margin-left: 5px;
}

.btn-warning {
    border-color: #f9b303;
    background: #f9b303;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
    border-radius: 10px;
    transform: translateY(0px);
}

.btn-warning:hover {
    transition: 0.5s;
    transform: translateY(-2px);
    box-shadow: 0px 3px 6px #00000069;
    border-color: #f9b303;
    background: #f9b303;
    color: #fff;
}

.btn-warning img {
    max-width: 15px;
    margin-right: 5px;
    margin-left: 5px;
}

.btn {
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
}

.btn:hover {
    transition: 0.5s;
    box-shadow: 0px 3px 6px #00000069;
}

.dropdown-menu {
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: unset;
    top: -5px !important;
    width: 100%;
    box-shadow: 0px 3px 6px #00000069;
}

.dropdown-toggle {
    padding-right: 35px;
}

.dropdown-toggle.show {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dropdown-toggle::after {
    background: url(../images/icons/arrow-down.svg) no-repeat;
    width: 15px;
    height: 10px;
    border: unset;
    top: 15px;
    position: absolute;
    right: 10px;
    left: auto;
}

.dropdown-toggle:focus {
    box-shadow: 0px 3px 6px #00000069;
}

.full-width {
    width: 100%;
}

.form-control {
    border-radius: 10px;
    border: unset;
}

.form-control:focus {
    box-shadow: 0px 3px 6px #00000069;
    outline: 0;
    border: unset;
}

.pl-0 {
    padding-left: 0px;
}

.relative {
    position: relative;
}

.pull-right {
    position: relative;
    display: inline-block;
    float: right;
}

.pull-left {
    position: relative;
    display: inline-block;
    float: left;
}

.clear {
    clear: both;
}

.main {
    background: #fafafa;
}

@media (min-width: 767px) {
    .pl-sm-0 {
        padding-left: 0px;
    }
}

.homeside {
    padding-bottom: 15px;
}

.sideImg_hold {
    position: relative;
    margin: 20px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.sideImg_hold .caption {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 30px 80px;
}

.sideImg_hold .caption button {
    width: auto;
}

.box_shadow {
    box-shadow: 0px 3px 6px #00000029;
}

.home_head_small {
    font-size: 16px;
    font-weight: bold;
    color: #575757;
    display: inline-block;
}

.footbg {
    opacity: 0.5;
}

.dwnld_app {
    /* margin-top: 15px; */
    position: relative;
    background: #2696cc;
}

.applink {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #fff;
    z-index: 1;
}

.applink h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0;
}

.applink p {
    font-size: 16px;
}

.applink .input-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
}

.applink .input-group .form-control {
    width: 100%;
    border-radius: 40px !important;
    overflow: hidden;
    padding: 15px;
    z-index: 1 !important;
}

.applink .input-group button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 45px;
    border-radius: 40px !important;
    transform: none !important;
    min-width: 120px;
    font-weight: bold;
}

.footer-last {
    padding: 15px 0px 0 0;
}

.logo-foot {
    margin: 15px 0px;
}

.footer-last p {
    color: #3c3c3c;
    max-width: 360px;
}

.social-links {
    display: flex;
}

.social-links li {
    height: 45px;
    width: 45px;
    border: 1px solid #2696cc;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
}

.social-links li:hover {
    background: #2696cc;
    transition: 0.5s;
}

.social-links li:hover img {
    filter: brightness(100);
}

.footer_links a {
    display: block;
    text-decoration: none;
    color: #3c3c3c;
    margin: 5px 0px;
}

.footer_links h3 {
    display: block;
    text-decoration: none;
    color: #383838;
    margin: 20px 0px 15px;
    font-size: 18px;
}

.copyryt {
    padding: 20px 15px;
}

.copyryt .pull-right {
    margin-right: 115px;
    font-weight: bold;
    text-decoration: none;
    color: #2696cc;
}

.copyryt p {
    font-weight: 500;
}

.copyryt .pull-right img {
    margin: 0px 15px;
}

@media (max-width: 992px) {
    .footbg {
        height: 400px;
        object-fit: cover;
    }
    .applink {
        padding: 0 15px;
    }
    .applink img {
        max-width: 130px;
    }
    .copyryt .pull-right {
        float: left;
    }
}

.radious {
    border-radius: 10px;
    overflow: hidden;
}

.storedesign {
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
}

.storedesign .rounded-circle {
    width: 80px;
    height: 80px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 35%);
    margin: 10px 15px;
    align-self: center;
}

.dtlStore {
    padding: 15px 0px;
}

.dtlStore h2 {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: #5a5a5a;
    margin-bottom: 0px;
}

.dtlStore p {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: #707070;
    margin: 0px;
    max-width: 85%;
}

.dtlStore li p {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #4b4b4b;
    font-weight: 500;
    display: inline-block;
}

.dtlStore li span {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #8d8d8d;
    font-weight: 500;
    display: inline-block;
}

.dtlStore li span strong {
    color: #6a6969;
}

.dtlStore li.lastLI {
    margin-right: 15px;
    border: 1px solid #707070;
    padding: 1px 8px;
    border-radius: 4px;
}

.dtlStore li img {
    display: inline-block;
}

.text-decoration-none {
    text-decoration: none;
}

.pagignation li a {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #848484;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    line-height: unset;
    margin: 0;
    display: block;
    color: #848484;
    font-weight: bold;
    line-height: 28px;
}

.pagignation li.active a {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #2696cc;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid #2696cc;
    color: #fff;
}

.HasHotDeals .htdls::after {
    width: 130px;
    height: 80px;
    content: "";
    background: url(../images/htdlsIIcn.png) no-repeat;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
}

.htdls span {
    color: #686666;
    font-size: 14px;
}

.starThing {
    width: 50px;
    border-radius: 5px;
    padding: 5px 5px;
    background: rgb(103 164 0 / 16%);
    text-align: center;
    display: inline-block;
}

.starThing h2 {
    font-weight: bold;
    font-size: 14px;
    color: #67a400;
    text-align: center;
    margin: 0px;
}

.starThing h2 .checked {
    color: #67a400;
}

.review {
    margin: 1px 0px;
}

.review .reviewLink {
    display: inline-block;
    margin-left: 5px;
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #a1a1a1;
}

.htdls .price {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 1px;
}

.htdls .price .oldprice {
    color: #c6c6c6;
    font-weight: 400;
    clear: both;
    display: block;
    text-decoration: line-through;
}

.htdls .addCart {
    color: #2696cc;
    border: 1px solid #2696cc;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    margin: 1px 0px;
    display: block;
    text-align: center;
    max-width: 190px;
}

.htdls .addCart img {
    width: 20px;
    vertical-align: middle;
}

.pl-15 {
    padding-left: 15px;
}

.addWishs {
    height: 100%;
    overflow: visible;
}

.addwish .heart {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin: 5px auto;
    display: block;
    margin-top: 23px;
    z-index: 99999;
    position: relative;
    /* top: -20px; */
}

.addwish {
    text-decoration: none;
}

.addwish span {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #4a4a4a;
    padding: 30px 8px;
    display: block;
    clear: both;
}

.addwish .hrt::before {
    width: 38px;
    height: 38px;
    background: #ff9faf;
    content: "";
    display: block;
    border-radius: 50%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.48;
    transition: 0.5s;
    margin-top: 15px;
}

.hrt {
    float: left;
    width: 100%;
    display: block;
    clear: both;
    transition: 0.5s;
}


/* .addWishs:hover .addwish .hrt .heart{
    transform: translateY(80px);
} */

.addWishs a::after {
    width: 100%;
    height: 100%;
    background: #ff9faf;
    content: "";
    display: block;
    border-radius: 5px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: 1.5s;
    margin-top: 0;
    opacity: 0;
}

.addWishs:hover a::after {
    width: 100%;
    height: 100%;
    background: #ff9faf;
    content: "";
    display: block;
    border-radius: 5px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.48;
    transition: 1.5s;
    margin-top: 0;
}

.addWishs:hover .addwish .hrt::before {
    transform: translateY(0px) scale(6);
    transition: 0.5s;
    animation-name: circleanimation;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.addWishs:hover span {
    display: none;
}

.addWishs:hover .addwish {
    height: 100%;
    transition: 0.5s;
}

.addWishs:hover .hrt {
    height: 100%;
    justify-content: center;
    flex-direction: column;
    transition: 0.5s;
    transform: translateY(80px);
}

@keyframes circleanimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.viewed .relative.radious.storedesign img.rounded {
    height: 217px;
    width: 170px;
    object-fit: cover;
}

.viewed .tags {
    display: block;
    margin: 5px 0px;
    float: left;
    max-width: 80%;
}

.viewed .tags p {
    display: inline-block;
    /* margin: 5px 10px 5px 0px; */
    margin: 0px 10px 0px 0px;
    float: left;
    font-size: 14px;
    color: #707070;
    line-height: 20px;
}

.viewed .tags p::after {
    display: inline-block;
    content: "|";
    margin-left: 10px;
}

.viewed .tags p:last-child::after {
    display: none;
}

.viewed .storename {
    margin: 2px 0px;
    clear: both;
    float: left;
    display: block;
    width: 100%;
}

.viewed .storename strong {
    font-weight: unset;
}

.viewed .starThing {
    padding: 2px 5px;
}

.viewed .review {
    float: left;
    width: 100%;
    display: block;
}

.viewed.HasHotDeals .htdls::after {
    background-size: 100%;
    width: 80px;
    height: 40px;
    bottom: 70px;
}

.viewed .htdls .addCart {
    max-width: 140px;
    font-size: 14px;
    padding: 5px 10px;
    float: right;
    margin-right: 15px;
}

.wishlistHeart {
    color: #d2d2d2;
    background: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 25px;
    top: 25px;
    text-align: center;
    line-height: 2;
}

.wishlistHeart:hover {
    color: #ff9faf;
}

.chx {
    border-radius: 0px 15px 0px 0px;
    background: rgb(207 207 207 / 67%);
    position: absolute;
    left: 16px;
    bottom: 16px;
    line-height: 0;
    padding: 5px 15px;
    overflow: hidden;
}

.chx a {
    text-decoration: none;
    color: #fff;
}

.viewed .rlt {
    position: relative;
}

.chx ul {
    margin-bottom: 0px;
}

.chx ul i {
    margin-right: 5px;
}

.viewed.str .price {
    float: left;
    display: block;
    clear: both;
    margin-top: 5px;
    width: 100%;
}

.viewed.str .addCart {
    margin-top: 15px;
}

.searchbar.inner-header {
    height: 70px;
    padding: 15px 0px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 41%);
    position: sticky;
    top: 0;
    z-index: 999;
    display: block;
}

.searchbar.inner-header .input-group .btn-primary {
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    margin-left: -10px !important;
    background: #fff;
    border-color: #fff;
}

.searchbar.inner-header .input-group .btn-primary img {
    filter: brightness(0);
}

header {
    position: sticky;
    top: 0px;
    z-index: 9;
}

.grey-bg-light {
    background: #efefef;
}

.filtered.viewed .relative.radious.storedesign img.rounded {
    height: 170px;
    width: 184px;
    object-fit: cover;
}

.WishlistHeader {
    width: 40px !important;
    height: 40px;
    background: #fff;
    margin-top: 0px;
    display: block;
    text-align: center;
    padding: 7px 0px;
}

.storeSide .dtlStore {
    padding: 0 10px 10px 10px;
}

.storeSide .dtlStore li img {
    display: inline-block;
    width: 10px;
}

.storeSide .dtlStore li {
    margin-right: 5px;
}

.storeSide .dtlStore li p {
    font-size: 10px;
    line-height: 10px;
}

.storeSide .dtlStore h2 {
    font-size: 14px;
}

.storeSide .rlt {
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.storeSide .rlt p {
    margin-bottom: 5px;
    font-size: 12px;
    margin-top: 5px !important;
}

.storeSide .rlt img {
    margin: 0px 15px;
}

.dtlStore .wtdht li {
    width: 100%;
}

.dtlStore .wtdht li p {
    font-size: 16px !important;
}

.filters_side {
    background: #fff;
    border: 1px solid #707070;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.fl {
    float: left;
    display: block;
    width: 100%;
}

.filters_side .p-2.fl {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.filters_side h2 {
    font-size: 16px;
    text-align: left;
    color: #484848;
    margin-bottom: 15px;
    vertical-align: middle;
    margin-top: 5px;
}

.filters_side .search_filter {
    border: 1px solid #707070;
    width: 25px;
    height: 25px;
    display: block;
    text-align: center;
    font-size: 14px;
    color: #7e7e7e;
}

.filters_side .loadmore {
    text-decoration: none;
    margin-left: 23px;
    font-weight: 600;
}

.brbtm {
    color: #707070;
}

.actv {
    background: #2696cc;
    color: #fff;
}

.filters_side .fl .btn {
    margin-bottom: 5px;
    margin-top: 5px;
}

.bg-light {
    background: #fff !important;
    height: 67px;
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 3px 6px #00000069;
}

.searchbar {
    background: url(../images/backgrounds/top_home.jpg) no-repeat center;
    background-size: cover;
    height: 155px;
}

.searchbar .d-flex {
    height: 100%;
}

.drop_full .dropdown-toggle {
    width: 100%;
    text-align: left;
}

img.thumbnail-image {
    width: 35px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}

.dropdown-item {
    padding: 0.25rem 12px;
}

@media (max-width: 575px) {
    .mt-xs-2 {
        margin: 5px 0px;
    }
    .searchbar {
        height: 275px;
    }
}

section {
    width: 100%;
    display: block;
    margin: 0px;
}

.bnr .slick-slider {
    overflow: hidden;
}

.bnr .slide {
    padding: 15px;
}

.bnr .slick-slide:not(.slick-active) {
    opacity: 0.5;
    transition: 1s;
    transform: translateY(50px);
}

.bnr .slick-slide {
    transform: translateY(0px);
    transition: 1s;
    margin-bottom: 50px;
}

.full-width {
    width: 100%;
}

.img_hold {
    border-radius: 30px;
    overflow: hidden;
}

@media (max-width: 992px) {
    .bnr .slick-slide:not(.slick-active) {
        transform: none;
    }
    .bnr .slide {
        padding: 15px;
    }
    .img_hold img {
        border-radius: 5px;
        overflow: hidden;
        min-height: 400px;
        object-fit: cover;
    }
}

.wishlist_hold {
    padding: 10px 15px;
}

.wishlist {
    background: #f9b303;
    width: 33px;
    display: inline-block;
    height: 31px;
    vertical-align: middle;
    border-radius: 5px;
    /* margin-left: 15px; */
    margin-right: 10px;
}

.wishlist img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 7px;
    vertical-align: middle;
    display: block;
    filter: brightness(100);
}

.home_head_small {
    font-size: 16px;
    font-weight: bold;
    color: #575757;
    display: inline-block;
}

.relative.hover_card img.full-width {
    min-height: 255px;
    object-fit: cover;
}

.wish_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    overflow: hidden;
}

.wish-cap {
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
    background: #fff;
    margin-top: -10px;
    z-index: 99999999;
    position: relative;
}

.wish-cap h2 {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish_card .tags p {
    display: inline-block;
    /* margin: 5px 10px 5px 0px; */
    margin: 0px 10px 0px 0px;
    float: left;
    font-size: 12px;
}

.wish_card .tags p::after {
    display: inline-block;
    content: "|";
    margin-left: 10px;
}

.wish_card .tags p:last-child::after {
    display: none;
}

.wish-cap h3.price {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish-cap a.addCart {
    color: #2696cc;
    border: 1px solid #2696cc;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.ve-scroll {
    padding: 15px 0px;
}

.wishlist_hold .slick-slide .wish-cap a.addCart img {
    display: inline-block;
}

.wishlist_hold .slick-initialized .slick-slide {
    display: block;
    padding: 15px 0px;
    padding-right: 15px;
}

.wish_card .pull-right img,
.wish_card .pull-right small {
    display: inline-block;
}

.store_card {
    box-shadow: 0px 3px 6px #00000029;
}

.store_card .store-cap {
    text-align: center;
    border: 1px solid #2696cc;
    padding: 10px 15px;
}

.wish_card,
.wish_card:focus,
.wish_card:visited {
    outline: none !important;
}

.box {
    /* border: 0.3px solid #707070; */
    box-shadow: 0px 0px 3px #00000029;
    padding: 15px;
}

.mapLinks {
    margin-top: 20px;
}

.map_foot {}

.pl-5 {
    padding-left: 50px;
}

.map_links {
    display: flex;
    justify-content: unset;
    flex-flow: wrap;
    margin-top: 30px;
}

.map_links a {
    color: #575757;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    margin: 10px;
    min-width: 115px;
}

.wishlist_hold {
    padding: 10px 15px;
}

.wishlist {
    background: #f9b303;
    width: 33px;
    display: inline-block;
    height: 31px;
    vertical-align: middle;
    border-radius: 5px;
    /* margin-left: 15px; */
    margin-right: 10px;
}

.wishlist img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 7px;
    vertical-align: middle;
    display: block;
    filter: brightness(100);
}

.wish_card {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    overflow: hidden;
}

.wish-cap {
    padding: 10px 15px 15px 15px;
    border-radius: 5px;
    background: #fff;
    margin-top: -10px;
    z-index: 99999999;
    position: relative;
}

.wish-cap h2 {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish_card .tags p {
    display: inline-block;
    /* margin: 5px 10px 5px 0px; */
    margin: 0px 10px 0px 0px;
    float: left;
    font-size: 12px;
}

.wish_card .tags p::after {
    display: inline-block;
    content: "|";
    margin-left: 10px;
}

.wish_card .tags p:last-child::after {
    display: none;
}

.wish-cap h3.price {
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    display: inline-block;
    margin-bottom: 0px;
}

.wish-cap a.addCart {
    color: #2696cc;
    border: 1px solid #2696cc;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 10px;
    box-shadow: 0px 3px 6px #00000029;
}

.ve-scroll {
    padding: 15px 0px;
}

.wishlist_hold .slick-slide .wish-cap a.addCart img {
    display: inline-block;
}

.wishlist_hold .slick-initialized .slick-slide {
    display: block;
    padding: 15px 0px;
    padding-right: 15px;
}

.wish_card .pull-right img,
.wish_card .pull-right small {
    display: inline-block;
}

.store_card {
    box-shadow: 0px 3px 6px #00000029;
}

.store_card .store-cap {
    text-align: center;
    border: 1px solid #2696cc;
    padding: 10px 15px;
}

.wish_card,
.wish_card:focus,
.wish_card:visited {
    outline: none !important;
}

.list-group-item,
.list-group-item.active {
    padding: 0px 0px;
    border: 1px solid transparent !important;
    margin: 0 0 6px 0px !important;
    border-radius: 10px !important;
    box-shadow: 0px 3px 6px #00000029;
    height: 57px;
    cursor: pointer;
    transition: 0.5s;
    color: #4b4b4b;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.list-group-item a,
.list-group-item.active a {
    color: #4b4b4b;
    text-decoration: none;
}

.list-group-item:hover,
.list-group-item.active {
    transition: 0.5s;
    box-shadow: 0px 3px 6px #00000069;
    background: #2696cc;
    color: #fff;
}

.list-group-item:hover a,
.list-group-item.active a {
    transition: 0.5s;
    color: #fff;
}

img.linkimg {
    background: #fff;
    padding: 10px 20px;
    margin-right: 15px;
    border-radius: 10px;
    object-fit: contain;
    height: 55px;
    width: 65px;
    /* border-right: 1px solid #707070 !important; */
    box-shadow: 0px 3px 6px #00000029;
}

.hover_card {
    overflow: hidden;
    border-radius: 15px !important;
    box-shadow: 0px 3px 6px #00000029;
    margin-bottom: 15px;
}

.hover_card .caption {
    border: 0.3px solid transparent;
    padding: 15px;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transform: translate(0px, 190px);
    transition: 0.5s;
    border-radius: 15px;
}

.hover_card .caption a {
    clear: both;
    width: auto;
    float: right;
    background: #2696cc;
    display: inline;
    padding: 0px 10px;
    border-radius: 15px;
    border: 1px solid #707070;
    margin: 5px 5px !important;
    color: #fff;
    text-decoration: none;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.hover_card .caption p {
    width: auto;
    display: inline;
    padding: 0px 10px;
    border-radius: 15px;
    border: 1px solid #707070;
    margin: 5px 5px !important;
    float: left;
    font-size: 12px;
}

.hover_card:hover .caption {
    transform: translate(0px, 0px);
    transition: 0.5s;
    /* border: 1px solid #707070; */
}

.hover_card .caption h2 {
    transform: translate(-60px, -15px);
    transition: 0.5s;
    display: inline-block;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
}

.hover_card:hover .caption h2 {
    transform: translate(10px, 0px);
    transition: 0.5s;
}

.hover_card .caption .icon {
    position: relative;
    right: 0;
    transition: 0.5s;
    transform: translate(235px, -40px);
    padding: 15px;
    background: #fff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    box-shadow: 0px 3px 6px #00000029;
}

.hover_card:hover .caption .icon {
    transform: translate(0px, 0px);
    transition: 0.5s;
    margin-bottom: 15px;
}

@media (max-width: 1399px) {
    .hover_card .caption {
        transform: translate(0px, 150px);
    }
    .hover_card .caption .icon {
        transform: translate(181px, -40px);
    }
}

@media (max-width: 1199px) {
    .hover_card .caption {
        transform: translate(0px, 175px);
    }
    .hover_card .caption .icon {
        transform: translate(215px, -40px);
    }
}

@media (max-width: 992px) {
    .hover_card .caption {
        transform: translate(0px, 130px);
    }
    .hover_card .caption .icon {
        transform: translate(151px, -40px);
        height: 50px;
        width: 50px;
    }
    .hover_card .caption h2 {
        transform: translate(-50px, -15px);
    }
    .hover_card:hover .caption {
        padding: 10px;
    }
    .hover_card:hover .caption .icon {
        margin: 0px 0px 5px 0px;
    }
    .hover_card .caption p {
        margin: 2px 2px !important;
        padding: 0px 5px;
    }
}

@media (max-width: 767px) {
    .hover_card .caption {
        transform: translate(0px, 150px);
    }
    .hover_card .caption .icon {
        transform: translate(180px, -40px);
        height: 50px;
        width: 50px;
    }
    .hover_card .caption h2 {
        transform: translate(-50px, -15px);
    }
    .hover_card:hover .caption {
        padding: 10px;
    }
    .hover_card:hover .caption .icon {
        margin: 0px 0px 5px 0px;
    }
    .hover_card .caption p {
        margin: 2px 2px !important;
        padding: 0px 5px;
    }
}

@media (max-width: 575px) {
    .hover_card .caption {
        position: relative;
        transform: none;
        top: -15px;
    }
    .hover_card .caption .icon {
        position: absolute;
        right: 15px;
        transform: none;
        top: -25px;
    }
    .hover_card .caption h2 {
        transform: none;
        margin-bottom: 5px;
        margin-left: 5px;
    }
    .hover_card .caption a {
        float: right;
        position: relative;
        clear: left;
        display: block;
        left: auto;
        top: 0;
        right: auto;
        width: auto;
        text-align: center;
        margin: 10px auto !important;
    }
    .caption .sub-tags {
        display: block;
        clear: both;
        width: 100%;
        float: left;
    }
}


/*Theme variables*/

.btn-success {
    border-color: #67a400;
    background: #67a400;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    transition: 0.5s;
    transform: translateY(0px);
}

.btn-success:hover {
    transition: 0.5s;
    transform: translateY(-2px);
    box-shadow: 0px 3px 6px #00000069;
}

.btn-success img {
    max-width: 15px;
    margin-right: 5px;
}

.btn-primary {
    border-color: #2696cc;
    background: #2696cc;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
    border-radius: 10px;
    transform: translateY(0px);
}

.btn-primary:hover {
    transition: 0.5s;
    transform: translateY(-2px);
    box-shadow: 0px 3px 6px #00000069;
    border-color: #2696cc;
    background: #2696cc;
    color: #fff;
}

.btn-primary img {
    max-width: 15px;
    margin-right: 5px;
    margin-left: 5px;
}

.btn-warning {
    border-color: #f9b303;
    background: #f9b303;
    color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
    border-radius: 10px;
    transform: translateY(0px);
}

.btn-warning:hover {
    transition: 0.5s;
    transform: translateY(-2px);
    box-shadow: 0px 3px 6px #00000069;
    border-color: #f9b303;
    background: #f9b303;
    color: #fff;
}

.btn-warning img {
    max-width: 15px;
    margin-right: 5px;
    margin-left: 5px;
}

.btn {
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
    transition: 0.5s;
}

.btn:hover {
    transition: 0.5s;
    box-shadow: 0px 3px 6px #00000069;
}

.dropdown-menu {
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: unset;
    top: -5px !important;
    width: 100%;
    box-shadow: 0px 3px 6px #00000069;
}

.dropdown-toggle {
    padding-right: 35px;
}

.dropdown-toggle.show {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dropdown-toggle::after {
    background: url(../images/icons/arrow-down.svg) no-repeat;
    width: 15px;
    height: 10px;
    border: unset;
    top: 15px;
    position: absolute;
    right: 10px;
    left: auto;
}

.dropdown-toggle:focus {
    box-shadow: 0px 3px 6px #00000069;
}

.full-width {
    width: 100%;
}

.form-control {
    border-radius: 10px;
    border: unset;
}

.form-control:focus {
    box-shadow: 0px 3px 6px #00000069;
    outline: 0;
    border: unset;
}

.pl-0 {
    padding-left: 0px;
}

.relative {
    position: relative;
}

.pull-right {
    position: relative;
    display: inline-block;
    float: right;
}

.pull-left {
    position: relative;
    display: inline-block;
    float: left;
}

.clear {
    clear: both;
}

.main {
    background: #fafafa;
}

@media (min-width: 767px) {
    .pl-sm-0 {
        padding-left: 0px;
    }
}

.homeside {
    padding-bottom: 15px;
}

.sideImg_hold {
    position: relative;
    margin: 20px 0px;
    box-shadow: 0px 3px 6px #00000029;
}

.sideImg_hold .caption {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 30px 80px;
}

.sideImg_hold .caption button {
    width: auto;
}

.box_shadow {
    box-shadow: 0px 3px 6px #00000029;
}

.home_head_small {
    font-size: 16px;
    font-weight: bold;
    color: #575757;
    display: inline-block;
}

.footbg {
    opacity: 0.5;
}

.dwnld_app {
    /* margin-top: 15px; */
    position: relative;
    background: #2696cc;
}

.applink {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #fff;
    z-index: 1;
}

.applink h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 15px 0;
}

.applink p {
    font-size: 16px;
}

.applink .input-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    max-width: 600px;
}

.applink .input-group .form-control {
    width: 100%;
    border-radius: 40px !important;
    overflow: hidden;
    padding: 15px;
    z-index: 1 !important;
}

.applink .input-group button {
    position: absolute;
    right: 4px;
    top: 4px;
    height: 45px;
    border-radius: 40px !important;
    transform: none !important;
    min-width: 120px;
    font-weight: bold;
}

.footer-last {
    padding: 15px 0px 0 0;
}

.logo-foot {
    margin: 15px 0px;
}

.footer-last p {
    color: #3c3c3c;
    max-width: 360px;
}

.social-links {
    display: flex;
}

.social-links li {
    height: 45px;
    width: 45px;
    border: 1px solid #2696cc;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: 0.5s;
    cursor: pointer;
}

.social-links li:hover {
    background: #2696cc;
    transition: 0.5s;
}

.social-links li:hover img {
    filter: brightness(100);
}

.footer_links a {
    display: block;
    text-decoration: none;
    color: #3c3c3c;
    margin: 5px 0px;
}

.footer_links h3 {
    display: block;
    text-decoration: none;
    color: #383838;
    margin: 20px 0px 15px;
    font-size: 18px;
}

.copyryt {
    padding: 20px 15px;
}

.copyryt .pull-right {
    margin-right: 115px;
    font-weight: bold;
    text-decoration: none;
    color: #2696cc;
}

.copyryt p {
    font-weight: 500;
}

.copyryt .pull-right img {
    margin: 0px 15px;
}

@media (max-width: 992px) {
    .footbg {
        height: 400px;
        object-fit: cover;
    }
    .applink {
        padding: 0 15px;
    }
    .applink img {
        max-width: 130px;
    }
    .copyryt .pull-right {
        float: left;
    }
}

.radious {
    border-radius: 10px;
    overflow: hidden;
}

.storedesign {
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
}

.storedesign .rounded-circle {
    width: 80px;
    height: 80px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 35%);
    margin: 10px 15px;
    align-self: center;
}

.dtlStore {
    padding: 15px 0px;
}

.dtlStore h2 {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    color: #5a5a5a;
    margin-bottom: 0px;
}

.dtlStore p {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
    color: #707070;
    margin: 0px;
    max-width: 85%;
}

.dtlStore li p {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #4b4b4b;
    font-weight: 500;
    display: inline-block;
}

.dtlStore li span {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #8d8d8d;
    font-weight: 500;
    display: inline-block;
}

.dtlStore li span strong {
    color: #6a6969;
}

.dtlStore li.lastLI {
    margin-right: 15px;
    border: 1px solid #707070;
    padding: 1px 8px;
    border-radius: 4px;
}

.dtlStore li img {
    display: inline-block;
}

.text-decoration-none {
    text-decoration: none;
}

.pagignation li a {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #848484;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    text-decoration: none;
    line-height: unset;
    margin: 0;
    display: block;
    color: #848484;
    font-weight: bold;
    line-height: 28px;
}

.pagignation li.active a {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background: #2696cc;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid #2696cc;
    color: #fff;
}

.HasHotDeals .htdls::after {
    width: 130px;
    height: 80px;
    content: "";
    background: url(../images/htdlsIIcn.png) no-repeat;
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
}

.htdls span {
    color: #686666;
    font-size: 14px;
}

.starThing {
    width: 50px;
    border-radius: 5px;
    padding: 5px 5px;
    background: rgb(103 164 0 / 16%);
    text-align: center;
    display: inline-block;
}

.starThing h2 {
    font-weight: bold;
    font-size: 14px;
    color: #67a400;
    text-align: center;
    margin: 0px;
}

.starThing h2 .checked {
    color: #67a400;
}

.review {
    margin: 1px 0px;
}

.review .reviewLink {
    display: inline-block;
    margin-left: 5px;
    text-decoration: none;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
    color: #a1a1a1;
}

.htdls .price {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 1px;
}

.htdls .price .oldprice {
    color: #c6c6c6;
    font-weight: 400;
    clear: both;
    display: block;
    text-decoration: line-through;
}

.htdls .addCart {
    color: #2696cc;
    border: 1px solid #2696cc;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 15px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    margin: 1px 0px;
    display: block;
    text-align: center;
    max-width: 190px;
}

.htdls .addCart img {
    width: auto;
    height: 17px;
    vertical-align: middle;
}

.pl-15 {
    padding-left: 15px;
}

.addWishs {
    height: 100%;
    overflow: visible;
}

.addwish .heart {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin: 5px auto;
    display: block;
    margin-top: 23px;
    z-index: 99999;
    position: relative;
    /* top: -20px; */
}

.addwish {
    text-decoration: none;
}

.addwish span {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #4a4a4a;
    padding: 30px 8px;
    display: block;
    clear: both;
}

.addwish .hrt::before {
    width: 38px;
    height: 38px;
    background: #ff9faf;
    content: "";
    display: block;
    border-radius: 50%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.48;
    transition: 0.5s;
    margin-top: 15px;
}

.hrt {
    float: left;
    width: 100%;
    display: block;
    clear: both;
    transition: 0.5s;
}


/* .addWishs:hover .addwish .hrt .heart{
    transform: translateY(80px);
} */

.addWishs a::after {
    width: 100%;
    height: 100%;
    background: #ff9faf;
    content: "";
    display: block;
    border-radius: 5px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transition: 1.5s;
    margin-top: 0;
    opacity: 0;
}

.addWishs:hover a::after {
    width: 100%;
    height: 100%;
    background: #ff9faf;
    content: "";
    display: block;
    border-radius: 5px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0.48;
    transition: 1.5s;
    margin-top: 0;
}

.addWishs:hover .addwish .hrt::before {
    transform: translateY(0px) scale(6);
    transition: 0.5s;
    animation-name: circleanimation;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.addWishs:hover span {
    display: none;
}

.addWishs:hover .addwish {
    height: 100%;
    transition: 0.5s;
}

.addWishs:hover .hrt {
    height: 100%;
    justify-content: center;
    flex-direction: column;
    transition: 0.5s;
    transform: translateY(80px);
}

@keyframes circleanimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.viewed .relative.radious.storedesign img.rounded {
    height: 217px;
    width: 170px;
    object-fit: cover;
}

.viewed .tags {
    display: block;
    margin: 5px 0px;
    float: left;
    max-width: 80%;
}

.viewed .tags p {
    display: inline-block;
    /* margin: 5px 10px 5px 0px; */
    margin: 0px 10px 0px 0px;
    float: left;
    font-size: 14px;
    color: #707070;
    line-height: 20px;
}

.viewed .tags p::after {
    display: inline-block;
    content: "|";
    margin-left: 10px;
}

.viewed .tags p:last-child::after {
    display: none;
}

.viewed .storename {
    margin: 2px 0px;
    clear: both;
    float: left;
    display: block;
    width: 100%;
}

.viewed .storename strong {
    font-weight: unset;
}

.viewed .starThing {
    padding: 2px 5px;
}

.viewed .review {
    float: left;
    width: 100%;
    display: block;
}

.viewed.HasHotDeals .htdls::after {
    background-size: 100%;
    width: 80px;
    height: 40px;
    bottom: 70px;
}

.viewed .htdls .addCart {
    max-width: 140px;
    font-size: 14px;
    padding: 5px 10px;
    float: right;
    margin-right: 15px;
}

.wishlistHeart {
    color: #d2d2d2;
    background: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 25px;
    top: 25px;
    text-align: center;
    line-height: 2;
}

.wishlistHeart:hover {
    color: #ff9faf;
}

.chx {
    border-radius: 0px 15px 0px 0px;
    background: rgb(207 207 207 / 67%);
    position: absolute;
    left: 16px;
    bottom: 16px;
    line-height: 0;
    padding: 5px 15px;
    overflow: hidden;
}

.chx a {
    text-decoration: none;
    color: #fff;
}

.viewed .rlt {
    position: relative;
}

.chx ul {
    margin-bottom: 0px;
}

.chx ul i {
    margin-right: 5px;
}

.viewed.str .price {
    float: left;
    display: block;
    clear: both;
    margin-top: 5px;
    width: 100%;
}

.viewed.str .addCart {
    margin-top: 15px;
}

.searchbar.inner-header {
    /* height: 70px; */
    height: auto;
    padding: 15px 0px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 41%);
    position: sticky;
    top: 0;
    z-index: 999;
    display: block;
}

.searchbar.inner-header .input-group .btn-primary {
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    margin-left: -10px !important;
    background: #fff;
    border-color: #fff;
}

.searchbar.inner-header .input-group .btn-primary img {
    filter: brightness(0);
}

header {
    position: sticky;
    top: 0px;
    z-index: 9;
}

.grey-bg-light {
    background: #efefef;
}

.filtered.viewed .relative.radious.storedesign img.rounded {
    height: 170px;
    width: 184px;
    object-fit: cover;
}

.WishlistHeader {
    width: 40px !important;
    height: 40px;
    background: #fff;
    margin-top: 0px;
    display: block;
    text-align: center;
    padding: 7px 0px;
}

.storeSide .dtlStore {
    padding: 0 10px 10px 10px;
}

.storeSide .dtlStore li img {
    display: inline-block;
    width: 10px;
}

.storeSide .dtlStore li {
    margin-right: 5px;
}

.storeSide .dtlStore li p {
    font-size: 10px;
    line-height: 10px;
}

.storeSide .dtlStore h2 {
    font-size: 14px;
}

.storeSide .rlt {
    text-align: center;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.storeSide .rlt p {
    margin-bottom: 5px;
    font-size: 12px;
    margin-top: 5px !important;
}

.storeSide .rlt img {
    margin: 0px 15px;
}

.dtlStore .wtdht li {
    width: 100%;
}

.dtlStore .wtdht li p {
    font-size: 16px !important;
}

.filters_side {
    background: #fff;
    border: 1px solid #707070;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.fl {
    float: left;
    display: block;
    width: 100%;
}

.filters_side .p-2.fl {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.filters_side h2 {
    font-size: 16px;
    text-align: left;
    color: #484848;
    margin-bottom: 15px;
    vertical-align: middle;
    margin-top: 5px;
}

.filters_side .search_filter {
    border: 1px solid #707070;
    width: 25px;
    height: 25px;
    display: block;
    text-align: center;
    font-size: 14px;
    color: #7e7e7e;
}

.filters_side .loadmore {
    text-decoration: none;
    margin-left: 23px;
    font-weight: 600;
}

.brbtm {
    color: #707070;
}

.actv {
    background: #2696cc;
    color: #fff;
}

.filters_side .fl .btn {
    margin-bottom: 5px;
    margin-top: 5px;
}

.vmmdl {
    vertical-align: middle;
}

.vmmdl a {
    text-decoration: none;
    height: 100%;
    vertical-align: middle;
    color: #fff;
    font-size: 16px;
}

.vmmdl a h4 {
    font-size: 16px;
}

.styledulCat {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.styledulCat .list-inline-item {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.catIcn {
    width: 40px !important;
}

.catIcn i,
.catIcn i img {
    width: 100%;
    height: 25px;
    object-fit: contain;
    width: 25px;
}

.catArrow {
    width: 20px !important;
    display: flex !important;
    text-align: right;
    flex-direction: row !important;
    justify-content: flex-end !important;
}

.catArrow img {
    /* display: block; */
    text-align: right;
    max-width: 8px;
}

.postAddSide .p-2.pb-0.fl.pt-0 {
    border-top: 1px solid;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.brtopnone {
    border-top: 0px solid transparent !important;
}

.postAddstepBtn {
    text-decoration: none;
}

.postAddstepBtn h1 {
    font-size: 22px;
    margin-bottom: 0px;
    color: #313131;
}

.postAddstepBtn h2 {
    font-size: 14px;
    margin-bottom: 0px;
    color: #313131;
}

.postAdsTopLinks li {
    padding: 15px;
}

.selected {
    background: #67a400;
}

.selected .postAddstepBtn h1,
.selected .postAddstepBtn h2 {
    color: #fff;
}

.box-shadow {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.styleLinkSubCat a {
    text-decoration: none;
    color: #4b4b4b;
    font-weight: 400;
    font-size: 14px;
}

.boxUpload {
    background: #fff;
    padding: 15px 25px;
    text-align: center;
    margin-bottom: 15px;
}

.boxUpload img {
    width: 80px;
    margin: 40px;
}

.boxUpload p {
    font-weight: 500;
    font-size: 16px;
    color: #606060;
    margin-bottom: 0px;
}

.lessHeight {
    padding: 10px 15px;
    position: relative;
}

.lessHeight img {
    width: 45px;
    margin: 5px;
}

.boxUpload.lessHeight .pickerImages {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.boxBgPostAd {
    background: #f3f3f3;
    /* border: 0.1px solid #707070; */
    padding: 15px 40px;
    margin-bottom: 15px;
    box-shadow: 0px 0px 3px rgb(0 0 0 / 16%);
}

.boxBgPostAd h1 {
    font-weight: 500;
    font-size: 24px;
    color: #5c5c5c;
}

.boxBgPostAd .form-control::placeholder {
    font-size: 14px;
    color: #888888;
}

.boxBgPostAd .form-control {
    border-radius: 5px;
    margin-bottom: 15px;
}

.boxBgPostAd .form-group {
    position: relative;
}

.boxBgPostAd label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    color: #484848;
}

.boxBgPostAd textarea.form-control {
    resize: none;
}

.postBtn .btn-warning {
    border-radius: 0px;
    padding: 10px;
    font-size: 25px;
}

.kms {
    position: absolute;
    right: 30px;
    bottom: 23px;
    margin: 0px;
    text-transform: capitalize;
    font-size: 14px;
    color: #888888;
}

p.pds {
    background: #67A400;
    width: 50px;
    height: 45px;
    border-radius: 5px;
    position: absolute;
    left: 12;
    bottom: 12px;
    margin: 0px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    font-size: 14px;
}

.pl-45 {
    padding-left: 56px;
}


/*New Design Starts*/


/*Store Profile Page Starts*/

.store-detail {
    background-color: #fff;
    border: 0.1px solid #707070;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 10px;
}

.store-detail h4 {
    color: #707070;
    font-weight: bo900ld;
    font-size: 16px;
}

.store-detail p {
    color: #707070;
    font-size: 12px;
}

.store-detail span {
    color: #747474;
    font-size: 14px;
    font-weight: 700;
}

.store-detail .btn {
    font-size: 16px;
    font-weight: 900;
    width: 100%;
}

.stote-profile-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    box-shadow: 0px 1px 1px -1px #707070;
    flex-wrap: wrap;
}

.store-profile-left {
    display: flex;
    flex-wrap: wrap;
}

.store-profile-icon {
    margin-top: -70px;
    margin-left: 30px;
}

.store-profile-header-right-box-top {
    background-color: #fff;
    box-shadow: 0 0 1px #707070;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 400px;
    justify-content: space-between;
}

.store-profile-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.store-profile-btns .btn {
    width: 48%;
}

.store-profile-title h3 {
    font-weight: bold;
    font-size: 32px;
    color: #575757;
}

.store-profile-title span {
    font-size: 14px;
    color: #484848;
}

.store-profile-title label {
    font-size: 14px;
    color: #484848;
    font-weight: 600;
}

.store-profile-header-right-box-top-element {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.store-profile-header-right-box-top-element>div {
    width: 100%;
    text-align: center;
}

.store-profile-header-right-box-top-element>div:first-child {
    font-weight: bold;
    font-size: 16px;
    color: #525151;
}

.store-profile-header-right-box-top-element>div:last-child {
    font-size: 12px;
    color: #5A5A5A;
}

.store-profile-tabs {
    margin-top: 20px;
}

.nav-tabs {
    border: 0;
}

.nav-tabs .nav-link {
    border: 0;
    padding: 10px 45px;
    color: #5A5A5A;
    font-size: 16px;
    font-weight: bold;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #67A400;
    border-bottom: 2px solid #67A400;
}


/*Store Profile Page Ends*/


/*Wishlist Page Design Starts*/

.dtlStore h2.wishlist-box {
    color: #505050;
    font-weight: 600;
    font-size: 16px;
}

.wishlist-box .addCart {
    background-color: #FFFFFF;
    border: 0.5px solid #2696CC;
    color: #6E6E6E;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.wishlist-box .addCart.remove-cart {
    background-color: #F3F3F3;
    border: 0.5px solid #A9A9A9;
    color: #6E6E6E;
}

.wishlist-box .starThing {
    background-color: #78BC05;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #fff;
}

.wishlist-box .starThing h2 {
    color: #fff;
}

.wishlist-box.htdls .price {
    color: #707070;
    font-weight: bold;
    font-size: 16px;
}

.wishlist-box.htdls .price small {
    font-size: 10px;
    font-weight: normal;
    display: block;
}

.your-wishlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    box-shadow: 0px 1px 1px -1px #707070;
    padding-bottom: 20px;
}

.wishlist-title {
    font-size: 24px;
    font-weight: 600;
    color: #686868;
}

.wishlist-search {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
}

.your-wishlist input {
    background-color: #FFFFFF;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: calc(100% - 60px);
}

.your-wishlist .btn {
    background-color: #fff;
    border: 1px solid #D2D2D2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.your-wishlist .btn img {
    filter: invert(1);
    -webkit-filter: invert(1);
}


/*Wishlist Page Design Ends*/


/*Product Detail Page Design Starts*/

.product-galary-box {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.product-galary-box {
    box-shadow: 0px 0 1px #707070;
    padding: 2px 0 2px 2px;
}

.product-galary {
    width: 15%;
}

.product-galary img {
    margin-bottom: 10px;
}

.product-img {
    margin-left: 10px;
    flex: 1;
    width: calc(85% - 10px);
}

.product-img .product-main-img img {
    width: 100%;
}

.product-buy-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.product-buy {
    background-color: #2696CC;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    width: calc(50% - 10px);
    padding: 10px;
    border: 0;
}

.product-buy img {
    height: 15px;
    margin-right: 10px;
}

.product-buy span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

.post-owner {
    background-color: #fff;
    box-shadow: 0px 0 1px #707070;
    padding: 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    margin-top: 20px;
}

.post-owner-title {
    font-size: 16px;
    font-weight: 600;
    color: #707070;
}

.shop-title {
    font-size: 12px;
    font-weight: 600;
    color: #656565;
}

.shop-location img {
    height: 12px;
}

.shop-location span {
    font-size: 10px;
    color: #747474;
}

.follow-post {
    background-color: #fff;
    border: 1px solid #67A400;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #707070;
    padding: 10px 20px;
    display: inline-block;
    cursor: pointer;
}

.post-owner-desc {
    margin-top: 10px;
}

.post-owner-desc span {
    color: #707070;
    font-size: 12px;
    font-weight: 600;
}

.post-owner-desc label {
    color: #262626;
    font-size: 12px;
    font-weight: bold;
}

.post-owner-desc label img {
    height: 14px;
    margin-right: 5px;
}

.post-owner-desc label small {
    font-size: 8px;
    font-weight: 600;
    color: #A9A9A9;
}

.product-desc {
    margin-top: 20px;
}

.product-name {
    color: #555555;
    font-size: 18px;
    font-weight: 600;
}

.product-price {
    font-size: 24px;
    font-weight: bold;
    color: #616161;
}

.product-price .old-price {
    color: #D2D2D2;
    font-size: 14px;
    text-decoration: line-through;
    margin-left: 5px;
}

.product-rating {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.product-star {
    background-color: #67A40029;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
}

.product-star span {
    color: #67A400;
    font-size: 14px;
    font-weight: bold;
    margin-right: 5px;
}

.product-rating span {
    color: #8F8F8F;
    font-size: 12px;
}

.product-other-details {
    margin-top: 20px;
}

.product-other-details .row {
    margin-bottom: 10px;
}

.product-other-details label {
    color: #848484;
    font-size: 16px;
    font-weight: 600;
}

.product-other-details img {
    border: 1px solid #707070;
    padding: 5px;
    margin-right: 10px;
    max-height: 60px;
}

.product-other-details [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.product-other-details [type=radio]+img {
    cursor: pointer;
}

.product-other-details [type=radio]:checked+img {
    outline: 1px solid #707070
}

.product-other-details .poduct-size [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.product-other-details .poduct-size [type=radio]+div {
    cursor: pointer;
    color: #848484;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 15px;
    border: 1px solid #707070;
    margin-right: 20px;
}

.product-other-details .poduct-size [type=radio]:checked+div {
    outline: 1px solid #707070
}

.product-other-details span {
    color: #707070;
    font-size: 14px;
}

.product-other-details b {
    color: #262626;
    font-size: 14px;
    font-weight: 600;
}

.product-other-details .poduct-size {
    display: flex;
    flex-wrap: wrap;
}

.product-other-details .poduct-size>div {
    color: #848484;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 15px;
    border: 1px solid #707070;
    margin-right: 20px;
}

.rating-review-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #848484;
    font-size: 16px;
    font-weight: 600;
}

.rating-review-title>div {
    display: flex;
    flex-wrap: wrap;
}

.rate-product-btn {
    background-color: #67A400;
    border-radius: 2px;
    padding: 5px 10px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border: 0;
}

.review-rate {
    background-color: #67A400;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    padding: 5px;
    display: inline-block;
    color: #fff;
    text-align: center;
}

.review-by {
    color: #7C7C7C;
    font-size: 10px;
}

.border-bottom {
    border-bottom: 1px solid #707070;
}

.product-options ul {
    list-style: disclosure-closed;
}

.product-options>div {
    font-size: 14px;
    font-weight: bold;
}


/*Product Detail Page Design Starts*/


/*Following Page Design Starts*/

.page-title {
    color: #686868;
    font-size: 24px;
    font-weight: 600;
}

.profile-detail {
    color: #9B9B9B;
    font-size: 12px;
}

.profile-detail .username {
    color: #686868;
    font-size: 16px;
    font-weight: 600;
}

.profile-detail .user-count {
    display: flex;
    flex-wrap: wrap;
}

.profile-detail .user-count>div {
    margin-right: 20px;
}

.profile-detail .user-count b,
.profile-detail .user-count span {
    width: 100%;
    display: block;
}

.profile-detail .user-count b {
    color: #525151;
    font-size: 16px;
    font-weight: bold;
}

.profile-detail .user-count span {
    color: #818080;
    font-size: 12;
    font-weight: 600;
}

.edit-profile {
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
    background-color: #fff;
    padding: 5px 20px;
    color: #686868;
    font-size: 12px;
    cursor: pointer;
}

.following-list .col-md-4 {
    border-right: 1px solid #565656;
}

.following-list .col-md-4:nth-child(3n) {
    border-right: 0;
}

.following-list .following-list-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.show-more-following {
    color: #2696CC;
    font-size: 16px;
    font-weight: bold;
}

.following-button {
    background-color: #fff;
    border: 1px solid #707070;
    padding: 3px 35px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    color: #5F5F5F;
}

.following-name {
    flex: 1;
    margin-left: 10px;
}

.show-more-following {
    color: #2696CC;
    font-size: 16px;
    font-weight: bold;
}

.following-suggestion-title {
    background-color: #F2F2F2;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
    padding: 10px;
    color: #565656;
    font-size: 16px;
    font-weight: bold;
}

.suggestion-box {
    background-color: #FFFFFF;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
    padding: 10px;
    margin-bottom: 20px;
}

.suggestion-box-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.suggestion-title {
    color: #5F5F5F;
    font-size: 14px;
    font-weight: 700;
}

.suggestion-desc {
    color: #A1A1A1;
    font-size: 10px;
    margin-top: 10px;
}

.follow-button {
    flex: 1;
    text-align: right;
}

.btn-follow {
    background-color: #2696CC;
    padding: 10px 20px;
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    border: 0;
    align-items: center;
}

.suggestion-sliders .slick-initialized .slick-slide {
    display: block;
    padding: 15px 0px;
    padding-right: 0px;
    padding-right: 15px;
    position: relative;
}

.suggestion-box-name {
    position: absolute;
    background-color: #FFFFFF;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
    bottom: 0;
    left: 0;
    width: calc(100% - 15px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
}

.suggestion-box-name>div:first-child {
    font-size: 11px;
    font-weight: bold;
    color: #707070;
}

.suggestion-box-name>div:last-child {
    display: flex;
    flex-wrap: wrap;
}


/*Follwoing Page Design Ends*/


/*My Ads Page Design Starts*/

.my-ads-title {
    font-size: 24px;
    font-weight: 700;
    color: #686868;
}

.ads-search {
    position: relative;
}

.ads-search img {
    filter: invert(1);
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    opacity: 0.8;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-filter: invert(1);
}

.ads-search input {
    background-color: #FFFFFF;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
    padding-left: 50px;
}

.ads-box-container {
    margin-bottom: 10px;
}

.ads-content-box {
    background-color: #FFFFFF;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px 0 10px 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    height: 160px;
}

.ads-content-box-title {
    color: #484848;
    font-size: 16px;
    font-weight: bold;
}

.ads-time {
    color: #767676;
    font-size: 14px;
}

.ads-footer {
    display: flex;
    flex-wrap: wrap;
}

.ads-footer>div {
    margin-right: 15px;
}

.ads-footer>div span:first-child {
    color: #727272;
    font-size: 14px;
}

.ads-footer>div span:last-child {
    color: #2696CC;
    font-size: 14px;
    font-weight: bold;
    margin-left: 3px;
}

.ads-price {
    background-color: #fff;
    border: 1px solid #707070;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding: 10px 20px 0;
    text-align: center;
}

.ads-price span {
    color: #484848;
    font-size: 14px;
}

.ads-price div {
    color: #484848;
    font-size: 22px;
    font-weight: bold;
    margin-top: 35px;
}

.ads-actin-box {
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 1px 0px 6px rgb(0 0 0 / 16%);
}

.btn-edit {
    background-color: #2696CC;
    border-color: transparent;
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    width: 100%;
}

.btn-deactive {
    background-color: #F9B303;
    border-color: transparent;
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    width: 100%;
}

.btn-remove {
    background-color: #FF2E2E;
    border-color: transparent;
    color: #fff;
    font-size: 14px;
    padding: 5px 20px;
    width: 100%;
}


/*My Ads Page Design Ends*/


/*My Chats Design Starts*/

.chats-status {
    background-color: #fff;
    border-width: 0.1px;
    border-style: solid;
    border-color: #707070;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

.btn-read {
    background-color: #FFFFFF;
    border-width: 0.3px;
    border-style: solid;
    border-color: #707070;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 100%;
    padding: 10px;
    color: #686868;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-bottom: 10px;
}

.chat-list-box {
    background-color: #FFFFFF;
    border-color: #707070;
    border-width: 0.1px;
    border-style: solid;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    padding: 0px 0 0px 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    align-items: center;
}

.chat-list-box>div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.chat-list-title {
    color: #484848;
    font-size: 16px;
    font-weight: bold;
}

.chat-duratin {
    position: relative;
    padding: 25px;
}

.chat-duratin::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    background-color: #D3D3D3;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.chat-right-icons {
    background-color: #fff;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    border-left: 0.3px solid #707070;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.icon-user {
    background-color: #2696CC;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}

.icon-star {
    background-color: #F9B303;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.icon-delete {
    background-color: #FF2E2E;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.chat-box-container {
    background-image: url("../images/chat/chat-bg.png");
    background-size: cover;
    padding: 20px;
    height: 750px;
    position: relative;
}

.chat-time {
    color: #515151;
    font-size: 16px;
    margin-top: 30px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.chat-message-box {
    background-color: #FFFFFF;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border: 0.3px solid #707070;
    padding: 10px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: calc(100% - 50px);
    margin-left: 15px;
}

.chat-box-left {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 30%;
    margin-top: 20px;
}

.chat-box-right {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 30%;
    margin-top: 20px;
    float: right;
}

.chat-box-right .chat-message-box {
    margin-left: 0;
    margin-right: 15px;
}

.chat-type-box {
    background-color: #F6F6F6;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border: 0.3px solid #707070;
    padding: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.chat-text {
    display: inline-block;
    position: relative;
    width: 60%;
}

.chat-text input {
    border-radius: 92px;
    height: 47px;
    padding-left: 20px;
}

.chat-text img {
    position: absolute;
    right: 10px;
    top: 10px;
}

.chat-file {
    width: 20%;
}

.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

.upload-btn-wrapper .btn {
    background-color: #fff;
    padding: 8px 20px;
    border-radius: 8px;
    width: 100%;
    height: 47px;
    border-radius: 92px;
    -webkit-border-radius: 92px;
    -moz-border-radius: 92px;
    -ms-border-radius: 92px;
    -o-border-radius: 92px;
    box-shadow: none;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.send-btn {
    width: 18%;
}

.btn-send-file {
    background-color: #2696CC;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border: 0.3px solid #707070;
    width: 100%;
    height: 47px;
    border-radius: 92px;
    -webkit-border-radius: 92px;
    -moz-border-radius: 92px;
    -ms-border-radius: 92px;
    -o-border-radius: 92px;
    border: 0;
    color: #fff;
}


/*My Chats Design Ends*/


/*Order Page Design Starts*/

.order-tab {
    border-bottom: 0.3px solid #707070;
}

.order-box {
    background-color: #ffffff;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    margin-top: 20px;
}

.order-box-header {
    background-color: #F8F8F8;
    border-radius: 10px 10px 0 0;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    -ms-border-radius: 10px 10px 0 0;
    -o-border-radius: 10px 10px 0 0;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
}

.order-box-header>div span {
    color: #969696;
    font-size: 10px;
}

.order-box-header>div label {
    color: #686868;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
}

.order-box-body {
    padding: 15px;
}

.order-box-title {
    font-size: 20px;
    font-weight: bold;
    color: #5A5A5A;
}

.dispatched-status {
    color: #78BC05;
    font-size: 10px;
    font-weight: 700;
}

.order-box-body-detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
}

.order-box-body-detail-center {
    flex: 1;
}

.order-review {
    display: flex;
    align-items: center;
}

.order-rating {
    background-color: #78BC05;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 5px;
}

.view-detail {
    color: #2696CC;
    font-size: 10px;
    font-weight: 700;
    margin-top: 10px;
}

.view-detail img {
    height: 8px;
}

.order-buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.btn-track {
    background-color: #FFFFFF;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px 40px;
    color: #6E6E6E;
    font-size: 12px;
    font-weight: 700;
    margin-top: 10px;
    border: 0.3px solid #A9A9A9;
}

.delivered-status {
    font-size: 20px;
    font-weight: bold;
    color: #5A5A5A;
}

.order-completed-box {
    background-color: #FFFFFF;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border: 0.3px solid #A9A9A9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
}

.order-completed-box h4 {
    color: #686868;
    font-size: 18px;
    font-weight: 700;
}

.order-completed-box span {
    margin-top: 10px;
    color: #686868;
    font-size: 14px;
}

.order-other-box {
    background-color: #FFFFFF;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    box-shadow: 3px 0px 6px rgb(0 0 0 / 16%);
    border: 0.3px solid #A9A9A9;
    padding: 10px;
    margin-bottom: 10px;
}

.order-other-box h4 {
    color: #686868;
    font-size: 18px;
    font-weight: 700;
}

.order-other-box p {
    color: #686868;
    font-size: 14px;
    margin-bottom: 5px;
}


/*Order Page Design Ends*/


/*Login Modal*/

.login-modal .modal-body {
    padding: 0;
}

.login-bg {
    background-image: url("../images/login-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.login-bg img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-box {
    padding: 10px 20px 30px 20px;
}

.login-title {
    color: #515151;
    font-size: 22px;
    position: relative;
}

.loing-desc {
    color: #828282;
    font-size: 12px;
    margin-top: 10px;
}

.close-modal {
    position: absolute;
    right: 10px;
    top: 20px;
}

.login-body .form-control {
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    padding: 10px;
    border: 0.2px solid #707070;
}

.login-body .btn-login {
    background-color: #2696CC;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    border: 0;
    padding: 10px;
}

.login-tc {
    color: #6F6F6F;
    font-size: 12px;
}

.login-footer-title {
    position: relative;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #757575;
}

.login-footer-title::before {
    position: absolute;
    content: "";
    height: 1px;
    background-color: #707070;
    width: 30%;
    left: 5%;
    top: 50%;
}

.login-footer-title::after {
    position: absolute;
    content: "";
    height: 1px;
    background-color: #707070;
    width: 30%;
    right: 5%;
    top: 50%;
}

.login-social-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.login-social-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    border: 1px solid #2696CC;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    width: 32%;
}

.login-social-box span {
    margin-left: 15px;
}

.otp-box {
    width: 20%;
}

.otp-box input {
    border: 1px solid #2696CC;
    height: 50px;
    border-radius: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
    width: 100%;
    padding: 5px;
}

.otp-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


/*New Design End*/