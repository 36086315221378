@import "assets/css/bootstrap.css";
@import "assets/css/slick.css";
@import "assets/css/style.css";

.cursor-pointer{
    cursor: pointer;
}
.error-text{
    color: red;
}
.success-text{
    color: green;
}

.text-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}